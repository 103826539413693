import fetch from '../utils/fetch'

// 速搭客服配置
export function getSodaInfo (query) {
  return fetch({
    url: '/im/soda',
    method: 'get',
    params: query
  })
}

export function saveSoda (params) {
  return fetch({
    url: '/im/soda',
    method: 'post',
    params: params
  })
}

// 获取店铺速搭客服配置
export function getDistributorSoda (distributor_id) {
  return fetch({
    url: '/im/soda/distributor/' + distributor_id,
    method: 'get'
  })
}

// 设置店铺速搭客服配置
export function setDistributorSoda (distributor_id, params) {
  return fetch({
    url: '/im/soda/distributor/' + distributor_id,
    method: 'put',
    params: params
  })
}

import fetch from '../utils/fetch'

export function promotion_activities_create (query) {
  return fetch({
    url: '/promotions/activities',
    method: 'post',
    params: query
  })
}
// /promotions/activities
export function promotion_activities (query) {
  return fetch({
    url: '/promotions/activities',
    method: 'get',
    params: query
  })
}
//  promotions/activities/cancel/

export function promotion_activities_cancel (query) {
  return fetch({
    url: '/promotions/activities/cancel/'+query,
    method: 'delete'
  })
}
// promotions/activities/cancelstore/{activities_id}
export function cancelstore (query) {
  return fetch({
    url: '/promotions/activities/cancelstore/'+query,
    method: 'delete'
  })
}
export function deleteActrecord (activities_id, actrecord_id) {
  return fetch({
    url: `/promotions/actrecord/${activities_id}?actrecord_id=${actrecord_id}`,
    method: 'delete'
  })
}
// /api/promotions/activities/
export function promotion_activities_id(id) {
  return fetch({
    url: '/promotions/activities/'+id,
    method: 'get'
  })
}
// api//promotions/actrecord/{activities_id}
export function actrecord(id) {
  return fetch({
    url: '/promotions/actrecord/'+id,
    method: 'get'
  })
}
export function actrecordsearch(id,mobile) {
  return fetch({
    url: '/promotions/actrecord/'+id+'?mobile='+mobile,
    method: 'get'
  })
}
export function promotion_activities_update(id, query) {
  return fetch({
    url: '/promotions/activities/'+id,
    method: 'put',
    params: query
  })
}

// api/espier/image
export function image(id, query) {
  return fetch({
    url: '/espier/image/'+id,
    method: 'put',
    params: query
  })
}

export function deleteImage (query) {
  return fetch({
    url: '/espier/images?image_id='+query,
    method: 'delete'
  })
}
// /nhsubmer/list
export function nhsubmer_list (query) {
  return fetch({
    url: '/nhsubmer/list',
    method: 'get',
    params: query
  })
}

export function nhsubmer_info (id) {
  return fetch({
    url: '/nhsubmer/info/'+id,
    method: 'get'
  })
}

export function nhsubmer_create (query) {
  return fetch({
    url: '/nhsubmer/create',
    method: 'put',
    params: query
  })
}

export function nhsubmer_update (id,query) {
  return fetch({
    url: '/nhsubmer/'+id,
    method: 'post',
    params: query
  })
}

export function nhsubmer_action(id,query) {
  return fetch({
    url: '/nhsubmer/action/'+id,
    method: 'post',
    params: query
  })
}
